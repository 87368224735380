@import './functions';

// colors
$purple: #B26295;
$raspberry: #E6516A;
$blackColor: #262626;
$whiteColor: #f2f2f2;
$subTitleColor: #999999;
$nightBgColor: #262626;
$lightBgColor: #f2f2f2;

// base
$mainBgColor: $nightBgColor;

//shadow
$shadowButton: 0 1px 4px 0 rgba(0, 0, 0, 0.15);

// font-size
$fontSize10: rem(10); // 10px
$fontSize12: rem(12); // 12px
$fontSize14: rem(14); // 14px
$fontSize16: rem(16); // 16px
$fontSize18: rem(18); // 18px
$fontSize20: rem(20); // 20px
$fontSize22: rem(22); // 22px
$fontSize23: rem(23); // 23px
$fontSize26: rem(26); // 26px
$fontSize28: rem(28); // 28px
$fontSize30: rem(30); // 30px
$fontSize32: rem(32); // 32px
$fontSize38: rem(38); // 38px
$fontSize40: rem(40); // 40px

$fwb: 600; // font weight bold

$fontFamily: 'Open Sans', sans-serif;